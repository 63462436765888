import { CTCard, CTCardHeader } from '@nbcuniversal/ct-ui';
import { useState } from 'react';
import { CTInput, CTButton, CTIcon, CTProgressCircular } from '@nbcuniversal/ct-ui';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { API_PATH } from '../../../apiConfig'
import checkMaintenanceMode from '../../../maintananceUtil';
import { request } from 'http';

interface RegistrationPost {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  last4OfSocial: string;
  personalEmail: string;
  sso?: string;
}

const RegistrationForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formTitle, setFormTitle] = useState('Let\'s get you registered');
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [last4OfSocial, setLast4OfSocial] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [nbcSso, setNbcSso] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formEmailReenter, setFormEmailReenter] = useState('');
  const [shouldDisplaySSN, setShouldDisplaySSN] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorMsg, setRequestErrorMsg] = useState('');
  const [invalidEmailMatch, setInvalidEmailMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const today = new Date();
  const maxDate = `${today.getFullYear()}-01-01`;

  const handleRecaptchaVerification = async () => {
    if (!executeRecaptcha) {
      console.log('executeRecaptcha method not available yet.');
      return;
    }

    return await executeRecaptcha('submit');
  };

  const checkEmailMatch = (newInput: string, comparedInput: string) => {
    if (!formEmailReenter) return;
    setInvalidEmailMatch(!(newInput === comparedInput));
  }
  const onSubmit = async () => {
    if (invalidEmailMatch) {
      return;
    }

    setIsLoading(true);

    const token = await handleRecaptchaVerification();

    try {
      const data: RegistrationPost = {
        firstName,
        lastName,
        dateOfBirth,
        last4OfSocial,
        personalEmail,
      }
      // optional and only provide if the value is set
      if (nbcSso) {
        data.sso = nbcSso;
      }
      // Check we are not in maintenance prior to posts..
      if (await checkMaintenanceMode() === true) {
        setIsLoading(false);
        setRequestError(true)
        const msg = "Portal is in Maintenance Mode"
        setRequestErrorMsg(msg);
        throw new Error(msg)
      }

      const response = await fetch(`${API_PATH}/registration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-recaptcha-token': token!
        },
        body: JSON.stringify(data)
      });

      if (response.status === 403) {
        const deniedRequestMessage = `You have been detected as a robot. Please use a different browser or try again later.`;
        setRequestError(true);
        setRequestErrorMsg(deniedRequestMessage);
        throw new Error(deniedRequestMessage);
      }

      const result = await response.json();
      if (result.redirect) {
        window.location.href = result.redirect;
      } else {
        setRequestError(true)
        if (result.message !== "" && result.message !== undefined) {
          setRequestErrorMsg(result.message);
        } else {
          setRequestErrorMsg("There was an Error processing your request.");
        }
        setIsLoading(false);
        return;
      }
    } catch (error: any) {
      if (requestError === false) {
        setRequestError(true)
        setRequestErrorMsg("There was an Error processing your request.");
      }
      console.error('There was an error!', error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="ct-flex ct-justify-center ct-items-center ct-mt-6 md:ct-p-4 sm:ct-p-4">
        <div className="lg:ct-w-1/2">
          <CTCard
            primary
            raised
          >
            <CTCardHeader
              className="ct-px-4"
              primary
            >
              <h4 className="ct-text-primary">{formTitle}</h4>
            </CTCardHeader>
            <div className="ct-p-4 ct-space-y-4">
              <p>
                Enter the personal information you shared with your employer.
              </p>
              <div className="sm:ct-flex sm:ct-flex-col md:ct-flex md:ct-items-center md:ct-space-x-2 md:ct-flex-row">
                <h6 className="sm:ct-w-12 ct-font-bold">First Name*</h6>
                <div className="ct-flex-1 ct-flex ct-items-center ct-space-x-2">
                  <div className="ct-flex-1">
                    <div className="sm:ct-w-24">
                      <CTInput disabled={isLoading} value={firstName} onChange={(e: string) => {
                        setFirstName(e)
                      }} className="ct-w-full ct-text-danger" editable type="text" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:ct-flex sm:ct-flex-col md:ct-flex md:ct-items-center md:ct-space-x-2 md:ct-flex-row">
                <h6 className="sm:ct-w-12 ct-font-bold">Last Name*</h6>
                <div className="ct-flex-1 ct-flex ct-items-center ct-space-x-2">
                  <div className="ct-flex-1">
                    <div className="sm:ct-w-24">
                      <CTInput disabled={isLoading} value={lastName} onChange={(e: string) => {
                        setLastName(e)
                      }} className="ct-w-full ct-text-danger" editable type="text" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:ct-flex sm:ct-flex-col md:ct-flex md:ct-items-center md:ct-space-x-2 md:ct-flex-row">
                <h6 className="sm:ct-w-12 ct-font-bold">Last 4 digits of SSN*</h6>
                <div className="ct-flex-1 ct-flex ct-items-center ct-space-x-2">
                  <div className="ct-flex-1">
                    <div className="sm:ct-w-24">
                      <CTInput disabled={isLoading} value={last4OfSocial} maxLength={4} onChange={(e: string) => {
                        setLast4OfSocial(e)
                      }} className="ct-w-full ct-text-danger" editable type={shouldDisplaySSN ? 'text' : 'password'} />
                    </div>
                  </div>
                  <CTButton onClick={() => setShouldDisplaySSN(!shouldDisplaySSN)} iconButton>
                    <CTIcon icon={shouldDisplaySSN ? 'visibility' : 'visibility_off'} />
                  </CTButton>
                </div>
              </div>
              <div className="sm:ct-flex sm:ct-flex-col md:ct-flex md:ct-items-center md:ct-space-x-2 md:ct-flex-row">
                <h6 className="sm:ct-w-12 ct-font-bold">DOB*</h6>
                <div className="ct-flex-1">
                  <div className="sm:ct-w-24">
                    <CTInput disabled={isLoading} max={maxDate} value={dateOfBirth} onChange={(e: string) => {
                      setDateOfBirth(e)
                    }} className="ct-w-full" editable type="date" />
                  </div>
                </div>
              </div>
              <div className="sm:ct-flex sm:ct-flex-col md:ct-flex md:ct-items-center md:ct-space-x-2 md:ct-flex-row">
                <h6 className="sm:ct-w-12 ct-font-bold">SSO ID</h6>
                <div className="ct-flex-1 ct-flex ct-items-center ct-space-x-2">
                  <div className="ct-flex-1">
                    <div className="sm:ct-w-24">
                      <CTInput disabled={isLoading} value={nbcSso} onChange={(e: string) => {
                        setNbcSso(e)
                      }} className="ct-w-full ct-text-danger" editable type="text" />
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Please enter your personal email. Your personal email will be your login id.
              </p>
              <div className="sm:ct-flex sm:ct-flex-col md:ct-flex md:ct-items-center md:ct-space-x-2 md:ct-flex-row">
                <h6 className="sm:ct-w-12 ct-font-bold">Personal Email*</h6>
                <div className="ct-flex-1">
                  <div className="sm:ct-w-24">
                    <CTInput disabled={isLoading} value={personalEmail} onChange={(e: string) => {
                      setPersonalEmail(e)
                      checkEmailMatch(e, formEmailReenter)
                    }} className="ct-w-full" editable type="email" />
                  </div>
                </div>
              </div>
              <div className="sm:ct-flex sm:ct-flex-col md:ct-flex md:ct-items-center md:ct-space-x-2 md:ct-flex-row">
                <h6 className="sm:ct-w-12 ct-font-bold">Re-enter Email*</h6>
                <div className="ct-flex-1">
                  <div className="sm:ct-w-24">
                    <CTInput disabled={isLoading} value={formEmailReenter} onChange={(e: string) => {
                      setFormEmailReenter(e)
                      checkEmailMatch(e, personalEmail)
                    }} className="ct-w-full" editable type="email" />
                  </div>
                </div>
              </div>
              {invalidEmailMatch &&
                <p className="ct-text-danger ct-text-lg ct-font-bold ct--mt-6">
                  Entered emails do not match, please try again.
                </p>
              }
              <div
                className="md:ct-flex md:ct-flex-row md:ct-items-end md:ct-justify-between sm:ct-flex-col sm:ct-flex">
                <div>
                </div>
                <div className="ct-flex ct-justify-end ct-mt-3">
                  <CTButton
                    disabled={invalidEmailMatch || !dateOfBirth || !firstName || !lastName || !last4OfSocial || !personalEmail || !formEmailReenter || isLoading}
                    onClick={onSubmit} primary
                    raised>Submit</CTButton>
                </div>
              </div>
            </div>
          </CTCard>
          {requestError &&
            <p className="ct-text-center ct-text-danger ct-font-bold ct-mt-4">
              {requestErrorMsg}
            </p>
          }
        </div>
      </div>
      {
        isLoading &&
        <div className="ct-z-10 ct-pt-12 ct-flex ct-justify-center ct-items-center ct-fixed ct-top-0 ct-left-0 ct-right-0 ct-bottom-0 ct-bg-black/[.33]">
          <CTProgressCircular primary />
        </div>
      }
    </>
  )
}

export default RegistrationForm;

