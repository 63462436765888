import Layout           from './pods/components/layout/Layout';
import RegistrationForm from './pods/components/registration-form/RegistrationForm';
import checkMaintenanceMode from './maintananceUtil';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {
  const RECAPTCHA_KEY = '6Lcwj3spAAAAAOVPgRWxQbikD3VtaUDZ-fczqoMS';
  checkMaintenanceMode();
  return (
    <>
      <Layout>
        <Layout.Nav/>
        <Layout.Body>
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
            <RegistrationForm/>
          </GoogleReCaptchaProvider>
        </Layout.Body>
      </Layout>
    </>
  );
}

export default App;
