import { API_PATH, MAINTENANCE_PAGE } from "./apiConfig";

const checkMaintenanceMode = async () => {
    return fetch(`${API_PATH}/configurations/maintenanceMode`).then( r => {
        return r.json().then( result => {
            console.log(`MAINTANCE MODE ${JSON.stringify(result)}`);
            if(result === true){
                window.location.href = MAINTENANCE_PAGE;
                return true;
            }
            return false;
        } )
    })
};

export default checkMaintenanceMode;