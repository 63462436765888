interface PathParams{
  clientId: string;
  url: string;
  redirectUri: string;
};
const hostname = window.location.hostname;

const API_MAP: { [key: string]: string } = {
  'alumni.thenowportal.com': 'https://api.thenowportal.com',
  'alumni.dev.aws.thenowportal.com': 'https://api.dev.aws.thenowportal.com',
  'alumni.stg.thenowportal.aws-preprod.nbcuni.com': 'https://stg.api.aws-preprod.nbcuni.com',
  'alumni.qa.aws.thenowportal.com': 'https://api.qa.aws.thenowportal.com',
  'alumni.qa2.aws.thenowportal.com': 'https://api.qa2.aws.thenowportal.com',
  'localhost': 'https://api.dev.aws.thenowportal.com'
};

const COGNITO_MAP: {[key:string]: PathParams} = {
  'alumni.thenowportal.com': {
    clientId: '6teetv8a6e39e0mrd9mcfj2fpn',
    url: 'https://auth.thenowportal.com',
    redirectUri: 'https://thenowportal.com'
  },
  'alumni.dev.aws.thenowportal.com': {
    clientId: '7ee5kgr14kedd9eddjr6j6kf7n', 
    url: 'https://us-east-1.auth.dev.aws.thenowportal.com', 
    redirectUri: 'https://cdn.dev.aws.thenowportal.com'
  },
  'alumni.stg.thenowportal.aws-preprod.nbcuni.com': {
    clientId:'1ua7tn199ida6v5928hodapjni', 
    url: 'https://auth.stg.thenowportal.aws-preprod.nbcuni.com', 
    redirectUri: 'https://stg.thenowportal.aws-preprod.nbcuni.com'
  },
  'alumni.qa.aws.thenowportal.com': {
    clientId: '1mngem2kn1606b7vgv36udj8h4', 
    url: 'https://us-east-1.auth.qa.aws.thenowportal.com', 
    redirectUri: 'https://cdn.qa.aws.thenowportal.com'
  },
  'alumni.qa2.aws.thenowportal.com': {
    clientId: '1i3cujp7bp8iad5tprvuuql0j6', 
    url: 'https://us-east-1.auth.qa2.aws.thenowportal.com', 
    redirectUri: 'https://cdn.qa2.aws.thenowportal.com'
  },
  'localhost': {
    clientId: '7ee5kgr14kedd9eddjr6j6kf7n', 
    url: 'https://us-east-1.auth.dev.aws.thenowportal.com', 
    redirectUri: 'https://cdn.dev.aws.thenowportal.com'
  }
};
// Use the mapping or default to the fallback
const API_PATH = API_MAP[hostname] || 'https://default-api.thenowportal.com/api';
const COGNITO_PATH = COGNITO_MAP[hostname] || 'alumni.dev.aws.thenowportal.com';
const MAINTENANCE_PAGE = "http://maintenance.nbcuni.com/";

export { API_PATH, COGNITO_PATH, MAINTENANCE_PAGE };