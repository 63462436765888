import Nav  from './nav/Nav';
import Body from './body/Body';

const Layout = (props: any) => {
  return (
    <div className="ct-pt-10">
      { props.children }
    </div>
  )
}

Layout.Nav = Nav;
Layout.Body = Body

export default Layout;