import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { COGNITO_PATH } from './apiConfig';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import checkMaintenanceMode from './maintananceUtil';

const router = createBrowserRouter([
    {
      path: "/",
      Component: () => {
        checkMaintenanceMode().then( (maintenanceMode) => {
          if(maintenanceMode === false){
            window.location.href=`${COGNITO_PATH.url}/oauth2/authorize?identity_provider=ALUMNI&redirect_uri=${COGNITO_PATH.redirectUri}&response_type=CODE&client_id=${COGNITO_PATH.clientId}&scope=openid`;
          } else{
            console.log("Portal is in Maintenance mode Not redirecting to login.")
          }
        });
        return null;
      },
    },
    {
      path: "/register",
      element: <App />,
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
